import React from 'react';

const Heatshrink: React.FC<{
    color: string;
    size: 'sm' | 'md' | 'lg';
}> = ({ color, size }) => (
    <svg
        width="128"
        height="128"
        viewBox="0 0 128 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {size === 'lg' && (
            <path
                d="M55.905 30.9001C55.9582 29.8357 56.8368 29 57.9025 29H70.0975C71.1633 29 72.0418 29.8357 72.095 30.9001L72.7423 43.8463C72.9068 47.1365 74.4167 50.2143 76.9178 52.3582L82.8095 57.4081C85.4692 59.6879 87 63.0161 87 66.5192V97C87 98.1046 86.1046 99 85 99H43C41.8954 99 41 98.1046 41 97V66.5192C41 63.0161 42.5308 59.6879 45.1905 57.4081L51.0822 52.3582C53.5833 50.2143 55.0932 47.1365 55.2577 43.8463L55.905 30.9001Z"
                fill={color}
            />
        )}
        {size === 'md' && (
            <path
                d="M55.905 30.9001C55.9582 29.8357 56.8368 29 57.9025 29H70.0975C71.1633 29 72.0418 29.8357 72.095 30.9001L72.8884 46.7679C72.9622 48.2433 73.3077 49.6924 73.9077 51.0423L79.9657 64.6729C80.6477 66.2072 81 67.8676 81 69.5466V97C81 98.1046 80.1046 99 79 99H49C47.8954 99 47 98.1046 47 97V69.5466C47 67.8676 47.3523 66.2072 48.0343 64.6729L54.0923 51.0423C54.6923 49.6924 55.0378 48.2433 55.1116 46.7679L55.905 30.9001Z"
                fill={color}
            />
        )}
        {size === 'sm' && (
            <path
                d="M55.9132 30.9092C55.9618 29.841 56.8419 29 57.9112 29H70.0888C71.1581 29 72.0382 29.841 72.0868 30.9092L72.9139 49.1063C72.971 50.361 73.2246 51.5989 73.6656 52.7749L78.236 64.9625C78.7412 66.3099 79 67.7371 79 69.176V97C79 98.1046 78.1046 99 77 99H51C49.8954 99 49 98.1046 49 97V69.176C49 67.7371 49.2588 66.3099 49.764 64.9625L54.3344 52.7749C54.7754 51.5989 55.029 50.361 55.0861 49.1063L55.9132 30.9092Z"
                fill={color}
            />
        )}
    </svg>
);

export default Heatshrink;
